import React from "react";
import PropType from "prop-types";
import _ from "styled-components";

const Form = props => <Wrapper {...props}>{props.children}</Wrapper>;

Form.defaultProps = {
  className: "form"
};

Form.propTypes = {
  onSubmit: PropType.func.isRequired
};

const Wrapper = _.form``;

export default Form;
