import React from "react";
import _ from "styled-components";

const Title = ({ children }) => <Text>{children}</Text>;

const Text = _.h1`
  font-size: 1.85rem;
  color: #30405A;
`;

export default Title;
