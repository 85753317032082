import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Container } from "../../components";
import { isMobile } from "../../helpers";
import _ from "styled-components";

const Navbar = props => {
  const [attributes, setAttributes] = useState({});

  useEffect(() => {
    const className = isMobile ? "collapse hidden" : "collapse show";
    setAttributes({ className });
  }, []);

  return (
    <Container>
      <Nav {...props} {...attributes} id="navbar">
        <Link to="/">Página inicial</Link>
        <Link to="/quem-somos">Quem Somos</Link>
        <Link to="/produtos">Produtos</Link>
        <Link to="/portifolio">Portfólio</Link>
        <Link to="/fale-conosco">Fale Conosco</Link>
      </Nav>
    </Container>
  );
}

Navbar.defaultProps = {
  light: false
}

const Nav = _.nav`
  text-align: center;
  background-color: ${({ backgroundColor }) => backgroundColor};

  a {
    color: ${({ light }) => (light ? "#FFFFFF" : "#30405A")};
    text-transform: uppercase;
    font-weight: bold;
    padding: 1rem 1.25rem;
    display: inline-block;
  }

  @media (max-width: 576px) {
    padding: 1rem 0;

    a {
      display: block;
      padding: .5rem 1.25rem;
    }
  }
`;

export default Navbar;
