
export const isEmpty = value => {
  if (typeof value === undefined || value === null || value === "") return true;
  return typeof value === "object" && !Object.keys(value).length;
};

export const isMobile = window.matchMedia("(max-width: 576px)").matches;

export const defaultSettings = {
  about: `
    Há mais de 6 anos no mercado, a Engelar oferece o pacote completo para
    sua cobertura. Fornecemos estrutura metálica em aço galvanizado,
    telhas, calhas e rufos. Além de fornecermos as matérias, ainda
    contamos com mão de obra registrada e especializada em coberturas.
    Nossos colaboradores possuem todas as certificações e equipamentos
    necessários para a execução das obras.
  `,
  phone: "(43) 3017-3716",
  facebook: "https://facebook.com",
  instagram: "https://instagram.com",
  linkedin: "https://linkedin.com"
};

export const onlyNumbers = (args = ``) => args.replace(/\D/ig, '');

export const shuffle = (array) => array.sort(() => Math.floor(Math.random() - 0.5));

export const instagram = shuffle(require("./instagram.json"));
