import React, { useState, useEffect } from "react";
import PropType from "prop-types";
import _ from "styled-components";
import { isEmpty } from "../../helpers";

const Notification = ({ type, message }) => {
  const [effect, setEffect] = useState("");

  useEffect(() => {
    switch (type) {
      case "danger":
      case "warning":
        return setEffect("shake");
      case "success":
        return setEffect("bounceIn");
      default:
        return setEffect("fadeIn");
    }
  }, [type]);

  if (isEmpty(message)) return null;

  return (
    <Wrapper className={`alert alert-${type} mt-3 mb-3 animated ${effect}`}>
      {message}
    </Wrapper>
  );
};

Notification.defaultProps = {
  type: "info",
  message: null
};

Notification.propTypes = {
  type: PropType.string.isRequired
};

const Wrapper = _.div`
  text-transform: lowercase;
  &::first-letter {
    text-transform: uppercase;
  }
`;

export default Notification;
