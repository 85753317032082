import React from "react";
import { hydrate, render } from "react-dom";
import { BrowserRouter } from "react-router-dom";

import "./index.css";
import "./icons.css";
import "./animate.css";

import { Routes } from "./config";
import * as serviceWorker from "./serviceWorker";

import { SettingsProvider } from "./contexts/settings";

const Website = () => (
  <BrowserRouter>
    <SettingsProvider>
      <Routes />
    </SettingsProvider>
  </BrowserRouter>
);

const website = document.getElementById("root");

website.hasChildNodes()
  ? hydrate(<Website />, website)
  : render(<Website />, website);

serviceWorker.unregister();
