import React from "react";
import { Helmet } from "react-helmet";
import { Layout, Slider, Container, Row, Col, Icon } from "../../components";
import { SettingsContext } from "../../contexts/settings";
import _ from "styled-components";

const Page = ({ sliders }) => (
  <Layout>
    <Helmet>
      <title>Engelar: Estruturas Metálicas, Londrina, PR.</title>
      <meta
        name="description"
        content="A Engelar entrega a seus clientes desde o projeto da cobertura com ART, materiais da estrutura em aço galvanizado, telhas, calhas, rufos até a execução de todo o serviço. Atendemos construtoras, obras residenciais e comerciais."
      />
    </Helmet>
    <Slider images={sliders} />
    <Intro id="intro" className="pt-3 pb-3 pt-md-5 pb-md-5 animated fadeIn">
      <Container>
        <h1 className="text-center text-uppercase text-blue mb-5">
          <span className="text-green text-3">Olá, somos</span>
          <strong className="d-block text-big">a ENGELAR!</strong>
        </h1>
        <SettingsContext.Consumer>
          {({ about }) => (
            <p className="text-center text-2 text-gray pl-md-5 pr-md-5">
              {about}
            </p>
          )}
        </SettingsContext.Consumer>
      </Container>
    </Intro>
    <section id="points" className="pb-5">
      <Container>
        <Row>
          <Col col={12} md={4}>
            <Icon className="mdi-alarm text-green text-big" />
            <h2 className="text-blue text-3">Fácil de montar</h2>
            <p className="text-gray text-1">
              Fornecemos projeto detalhado e{" "}
              <strong>
                fabricamos os perfis em aço galvanizado sob medida
              </strong>
              . Os perfis são todos identificados para facilitar o manuseio e a
              instalação no canteiro de obras.
            </p>
          </Col>
          <Col col={12} md={4}>
            <Icon className="mdi-cash text-green text-big" />
            <h2 className="text-blue text-3">Menor Custo</h2>
            <p className="text-gray text-1">
              Possuímos fabricação própria e elaboramos projetos customizados
              para minimizar possíveis desperdícios de material. Desta forma,
              entregamos a solução completa para sua cobertura com custo
              competitivo.
            </p>
          </Col>
          <Col col={12} md={4}>
            <Icon className="mdi-trophy-award text-green text-big" />
            <h2 className="text-blue text-3">Qualidade</h2>
            <p className="text-gray text-1">
              Contamos com um rigoroso controle de qualidade da nossa
              matéria-prima. O <strong>aço</strong> da <strong>Engelar</strong>{" "}
              é <strong>rastreado</strong> e acompanha{" "}
              <strong>certificado da usina</strong>.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
    <section id="services" className="pt-5 pb-5 light-bg">
      <Container>
        <h2 className="text-center text-uppercase text-blue mb-5">
          <span className="text-green text-3">Conheça nosso projeto</span>
          <strong className="d-block text-5 m-2 m-md-5 mb-0">
            Cobertura em aço leve galvanizado (light steel framing) de 262 casas
            em conjunto habitacional.
          </strong>
        </h2>
        <p className="text-center text-2 text-gray pl-md-5 pr-md-5">
          Fornecemos soluções de cobertura em light steel framing para sua obra.
          Nós atendemos obras residencias, comerciais e conjuntos habitacionais
          minha casa minha vida (MCMV)
        </p>
        <center className="m-5">
          <Iframe
            src="https://www.youtube.com/embed/LBDIr52WveI?controls=0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></Iframe>
        </center>
      </Container>

      <p className="mt-5 text-center">
        <a
          href="https://www.instagram.com/engelarestruturas/"
          target="_blank"
          rel="noopener noreferrer"
          className="btn rounded-pill btn-outline-success mr-3"
        >
          <Icon className="mdi-instagram mr-3" />
          Visite nosso Instagram!
        </a>
        <a
          href="https://www.youtube.com/channel/UCVS0CxibGC5sgROKjobRFZw"
          target="_blank"
          rel="noopener noreferrer"
          className="btn rounded-pill btn-outline-success ml-3"
        >
          <Icon className="mdi-youtube mr-3" />
          Inscreva-se no YouTube!
        </a>
        
      </p>
    </section>
  </Layout>
);

const Intro = _.section`
  @media (max-width: 576px) {
    background-image: url(${require("../../assets/heading.jpg")});
    background-repeat: no-repeat;
  }
`;

const Iframe = _.iframe`
  width: 100%;
  height: 485px;
  border: 0;

  @media (max-width: 576px) {
    height: 320px;
  }
`;

export default Page;
