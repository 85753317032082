import { Request } from ".";

class Contact {
  constructor(attributes = {}) {
    this.attributes = { ...attributes };
  }

  async send() {
    return await Request.post("contacts", this.attributes);
  }
}

Contact.schema = {
  name: "",
  email: "",
  phone: "",
  subject: "",
  message: ""
};

export default Contact;
