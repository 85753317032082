import React from "react";
import PropTypes from "prop-types";
import { Image } from "../../components";

const Slider = ({ images }) => {
  if (!images.length) return null;

  return (
    <section
      id="slider"
      className="carousel slide carousel-fade animated fadeIn"
      data-ride="carousel">
      <div className="carousel-inner d-none d-sm-block d-md-block d-lg-block">
        {
          images.map((e, index) => (
            <div key={e.id} className={`carousel-item ${index === 0 ? 'active' : null}`}>
              <Image src={e.attributes.image} alt={e.attributes.title} className="w-100" />
            </div>
          ))
        }
      </div>
      <a
        className="carousel-control-prev"
        href="#slider"
        role="button"
        data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Anterior</span>
      </a>
      <a
        className="carousel-control-next"
        href="#slider"
        role="button"
        data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Próximo</span>
      </a>
      <ol className="carousel-indicators d-none d-sm-flex">
        {
          images.map((e, index) => (
            <li
              key={e.id}
              data-target="#slider"
              data-slide-to={index}
              className={`${index === 0 ? 'active' : null}`}>
            </li>
          ))
        }
      </ol>
    </section>
  );
};

Slider.defaultProps = {
  images: []
};

Slider.propTypes = {
  images: PropTypes.array
};

export default Slider;
