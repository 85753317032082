import React from "react";
import Lightbox from "react-simple-lightbox";
import { Container, Loading, Empty, Row, Col, Image } from "../../components";

export default ({ data, busy }) => {
  if (busy) return <Loading />;

  if (!data.length) return <Empty />;

  return (
    <Container className="mt-5">
      {data.map((e, index) => (
        <Row key={index}>
          <Col col={12}>
            <h3 className="text-blue text-titlecase">{e.attributes.title}</h3>
            <p className="text-secondary">{e.attributes.description}</p>
            <Row className="mb-md-4 mb-3">
              {e.attributes.images.map((image, key) => (
                <Col col={6} md={3} key={key}>
                  <Lightbox>
                    <Image src={`http://ws.engelar.eng.br/${image.path}`} alt={e.attributes.title} className="img-thumbnail preview" />
                  </Lightbox>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      ))}
    </Container>
  );
};
