import React from "react";
import { Layout, Heading } from "../../components";

import Content from "../shared/_content";

const Page = props => (
  <Layout>
    <Heading
      title="Produtos"
      label="Os perfis em light steel frame são de aço galvanizado e produzidos no material ZAR 230 - Z275 com espessura 0.80mm e 0.95mm."
    />
    <Content {...props} />
  </Layout>
);

export default Page;
