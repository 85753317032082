import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Icon, Image, Navbar } from "../../components";
import { SettingsContext } from "../../contexts/settings";
import { onlyNumbers, defaultSettings } from "../../helpers";
import _ from "styled-components";

const Header = () => (
  <SettingsContext.Consumer>
    {({ phone }) => (
      <>
        <Wrapper>
          <Container>
            <Row>
              <Col col={4} hiddenXsDown>
                <div className="d-flex flex-row pt-2 align-items-center animated fadeInLeft">
                  <Icon className="mdi-clock-outline" />
                  <div className="ml-3">
                    <strong>Seg a Sex</strong>
                    <big>08h00 às 18h00</big>
                  </div>
                </div>
              </Col>
              <Col col={12} md={4} className="d-flex justify-content-center">
                <button
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbar"
                >
                  <Icon className="mdi-menu" />
                </button>
                <Link to="/" className="animated fadeIn">
                  <Image
                    src={require("../../assets/logo.png")}
                    alt="Engelar Estruturas em Aço Leve"
                  />
                </Link>
                <a
                  href={`tel:+55${onlyNumbers(phone)}`}
                  title="Ligar agora!"
                  className="phone d-sm-none d-md-none d-lg-none"
                >
                  <Icon className="mdi-phone-classic" />
                </a>
              </Col>
              <Col col={4} hiddenXsDown>
                <div className="d-flex flex-row pt-2 align-items-center float-right animated fadeInRight">
                  <div className="text-right mr-3">
                    <strong>Fale conosco</strong>
                    <big>
                      <Tel href={`tel:+55${onlyNumbers(phone)}`} title="Ligar agora!">
                        { defaultSettings.phone || phone}
                      </Tel>
                    </big>
                  </div>
                  <Icon className="mdi-phone-classic" />
                </div>
              </Col>
            </Row>
          </Container>
        </Wrapper>
        <Navbar />
      </>
    )}
  </SettingsContext.Consumer>
);

const Wrapper = _.div`
  padding: 0.65rem 0;
  border-bottom: .035em solid #f0f0f0;

  strong {
    color: #A8C85A;
    text-transform: uppercase;
    display: block;
    font-size: 1rem;
  }

  big {
    font-weight: bold;
    font-size: 1.25rem;
  }

  i, big {
    color: #30405A;
  }

  i { font-size: 2rem }

  img {
    max-height: 4.68rem;
  }

  button {
    background-color: transparent;
    border: 0;
    display: none;
    position: absolute;
    top: .5rem; left: .5rem;
  }

  .phone {
    position: absolute;
    top: .5rem; right: 1rem;
  }

  @media (max-width: 576px) {
    button {
      display: block
    }
  }
`;

const Tel = _.a`
  font-size: 1.25rem !important;
  color: #30405A !important;
  margin-right: 0 !important;
`;

export default Header;
