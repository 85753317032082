import React, { useEffect } from "react";
import {
  Layout,
  Heading,
  Container,
  Form,
  TextInput,
  Row,
  Col,
  TextArea,
  Submit,
  Notification,
} from "../../components";

const Page = (props) => {
  useEffect(() => {
    window.gtag("event", "conversion", {
      send_to: "AW-957226647/qU3ACOnJzuUBEJe9uMgD",
      transaction_id: "",
    });
  });

  return (
    <Layout>
      <Heading
        title="Fale Conosco"
        label="Precisa fazer uma nova cobertura? Conte com a gente!"
      />
      <Container className="pt-5 pb-5 text-gray contact-form animated fadeIn">
        <div className="form-center">
          <Form onSubmit={props.handleOnSubmit}>
            <h2 className="text-blue">Formulário de contato</h2>
            <p>
              Preencha os campos abaixo para entrar em contato com nossa equipe,
              estamos à disposição:
            </p>
            <Notification {...props.notice} />
            <Row>
              <Col col={12}>
                <TextInput
                  name="name"
                  value={props.params.name}
                  onChange={props.handleOnChange}
                  disabled={props.busy}
                  placeholder="Nome completo"
                />
              </Col>
            </Row>
            <Row>
              <Col col={12} md={6}>
                <TextInput
                  name="email"
                  value={props.params.email}
                  onChange={props.handleOnChange}
                  disabled={props.busy}
                  placeholder="E-mail"
                />
              </Col>
              <Col col={12} md={6}>
                <TextInput
                  name="phone"
                  value={props.params.phone}
                  onChange={props.handleOnChange}
                  disabled={props.busy}
                  placeholder="Telefone"
                />
              </Col>
            </Row>
            <Row>
              <Col col={12}>
                <TextInput
                  name="subject"
                  value={props.params.subject}
                  onChange={props.handleOnChange}
                  disabled={props.busy}
                  placeholder="Assunto"
                />
              </Col>
            </Row>
            <Row>
              <Col col={12}>
                <TextArea
                  name="message"
                  value={props.params.message}
                  onChange={props.handleOnChange}
                  disabled={props.busy}
                  placeholder="Escreva aqui sua mensagem..."
                />
              </Col>
            </Row>
            <Submit title="Enviar mensagem" busy={props.busy} />
          </Form>
        </div>
      </Container>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7350.157515859949!2d-51.116373682789664!3d-23.29572729973284!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7502055b43591c09!2sEngelar%20Estruturas%20em%20a%C3%A7o%20leve!5e0!3m2!1sen!2sbr!4v1569956362139!5m2!1sen!2sbr"
        width="100%"
        height="450"
        frameBorder="0"
        title="Localização"
        style={{ border: 0 }}
      />
    </Layout>
  );
};

export default Page;
