import React from "react";
import { Layout, Heading, Container, Row, Col, Image } from "../../components";

import Content from "../shared/_content";

const Page = props => (
  <Layout>
    <Heading
      title="Portifólio"
      label="Soluções personalizadas para diversos tipos de coberturas. Atendemos construtoras, obras comerciais e residências."
    />
    <Container className="mt-3 mt-md-5">
      <Row>
        <Col col={12} md={4} className="text-center">
          <Image
            src={require("../../assets/rule.png")}
            alt="Dimensionamos"
            className="m-4 animated tada"
          />
          <h3 className="text-blue">Dimensionamos</h3>
          <p className="text-secondary">
            Dimensionamos a sua estrutura de acordo com sua necessidade.
          </p>
        </Col>
        <Col col={12} md={4} className="text-center">
          <Image
            src={require("../../assets/worker.png")}
            alt="Projetamos"
            className="m-4 animated tada"
          />
          <h3 className="text-blue">Projetamos</h3>
          <p className="text-secondary">
            Com nossa equipe especializada elaboramos projetos detalhados.
          </p>
        </Col>
        <Col col={12} md={4} className="text-center">
          <Image
            src={require("../../assets/building.png")}
            alt="Executamos"
            className="m-4 animated tada"
          />
          <h3 className="text-blue">Executamos</h3>
          <p className="text-secondary">
            Atendemos todas as normas para uma execução produtiva e bem
            sucedida.
          </p>
        </Col>
      </Row>
    </Container>
    <Content {...props} />
  </Layout>
);

export default Page;
