import React from "react";
import PropTypes from "prop-types";
import _ from "styled-components";

const Background = ({ src = "", children }) => (
  <Wrapper src={src}>{children}</Wrapper>
);

Background.propTypes = {
  src: PropTypes.string.isRequired
};

const Wrapper = _.div`
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: cover;
`;

export default Background;
