import React from "react";
import {
  Layout,
  Container,
  Row,
  Col,
  Icon,
  Image,
  Heading,
} from "../../components";

const Page = () => (
  <Layout>
    <Heading
      title="Quem Somos"
      label="A Engelar Estruturas em Aço Leve está localizada em Londrina-PR. A empresa surgiu da visão de seus diretores de contribuir com a industrialização da construção civil, por meio de sistemas construtivos baseados em estruturas metálicas e perfis formados a frio."
    />
    <section id="intro" className="pt-5 pb-5 animated fadeInUp">
      <Container>
        <h1 className="text-center text-uppercase text-blue mb-5">
          <strong className="d-block text-big">A ENGELAR</strong>
        </h1>
        <p className="text-center text-2 text-gray pl-md-5 pr-md-5">
          Fundada em 2013 a Engelar oferece a solução completa para cobertura do
          seu empreendimento. Fornecemos a estrutura metálica em aço
          galvanizado, calhas, rufos e telhas. Além de fornecermos os materiais,
          ainda contamos com mão de obra registrada e altamente qualificada.
          Nossos colaboradores possuem todas as certificações e equipamentos
          necessários para a execução de todos os tipos de coberturas.
        </p>
      </Container>
    </section>
    <section className="pb-5 text-gray animated fadeInUp">
      <Container>
        <Row>
          <Col col={12} md={4}>
            <Icon className="mdi-bullseye text-green text-big" />
            <h2 className="text-3 text-blue mb-2">Missão</h2>
            <p>
              Contribuir com a industrialização da construção civil brasileira,
              por meio de sistemas construtivos baseados em estruturas metálicas
              e perfis formados a frio.
            </p>
          </Col>
          <Col col={12} md={4}>
            <Icon className="mdi-eye-circle-outline text-green text-big" />
            <h2 className="text-3 text-blue mb-2">Visão</h2>
            <p>
              Ser referência em soluções completas para coberturas em aço leve
              no estado do Paraná.
            </p>
          </Col>
          <Col col={12} md={4}>
            <Icon className="mdi-star-circle-outline text-green text-big" />
            <h2 className="text-3 text-blue mb-2">Valores</h2>
            <p>
              A Engelar tem como valor principal a satisfação de seus clientes!
              Buscando sempre inovar em seus projetos com comprometimento,
              confiança e transparência.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
    <section id="highlight" className="pt-5 pb-5 light-bg">
      <Container>
        <h2 className="text-blue text-center">
          Porque escolher o sistema de cobertura da Engelar:
        </h2>
        <Row>
          <Col col={12} md={7} className="animated fadeInLeft">
            <p className="text-gray">
              <ol className="mt-5 mb-5 text-2">
                <li>
                  Elaboração de <strong>projetos de forma detalhada</strong> com
                  fornecimento de <strong>ART</strong>.
                </li>
                <li>
                  <strong>Fabricação de perfis sob medida</strong> e com
                  identificação das peças, conforme projeto.
                </li>
                <li>
                  O sistema permite a{" "}
                  <strong>utilização de qualquer tipo de telha</strong>.
                </li>
                <li>
                  <strong>Agilidade na montagem</strong>, garantindo maior{" "}
                  <strong>produtividade</strong> no canteiro.
                </li>
                <li>
                  <strong>Leve, rápido e durável</strong>. Garantia de 20 anos
                  contra defeitos de fabricação. Vida útil estimada em mais de 50 anos.
                </li>
                <li>
                  Imune a ataques de cupins, não empena, não absorve água e não
                  propaga chama.
                </li>
                <li>
                  <strong>Qualidade</strong>. Todos os materiais são
                  padronizados, cumprindo as{" "}
                  <strong>normas técnicas brasileiras (NBR's)</strong>
                </li>
                <li>
                  <strong>Sustentabilidade.</strong>. O aço é 100% reciclável e há
                  baixa geração de resíduos na fabricação.
                </li>
              </ol>
            </p>
          </Col>
          <Col col={12} md={5} className="animated fadeInRight">
            <Image
            className="mt-5"
              src={require("../../assets/about.png")}
              alt="Porque escolher o sistema de cobertura da Engelar"
            />
          </Col>
        </Row>
      </Container>
    </section>
    <section className="pt-3 pb-5 dark-bg">
      <Container>
        <h2 className="text-center m-5">
          Razões pela qual você deve escolher a Engelar em seu próximo projeto:
        </h2>
        <Row>
          <Col col={12} md={3}>
            <h3 className="text-3 text-green mb-2">Dimensionamento</h3>
            <p>
              Equipe especializada em dimensionar a estrutura de acordo com a
              sua necessidade.
            </p>
          </Col>
          <Col col={12} md={3}>
            <h3 className="text-3 text-green mb-2">Projetos</h3>
            <p>
              Elaboramos o seu projeto de maneira personalizada e detalhada.
            </p>
          </Col>
          <Col col={12} md={3}>
            <h3 className="text-3 text-green mb-2">Normas</h3>
            <p>
              Atendemos todas as normas e padrões universais de dimensionamento
              e execução.
            </p>
          </Col>
          <Col col={12} md={3}>
            <h3 className="text-3 text-green mb-2">Execução</h3>
            <p>
              Instalação de estrutura metálica em aço galvanizado, calhas, rufos
              e telhas.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>
);

export default Page;
