import React from "react";
import _ from "styled-components";

export default () => (
  <center>
    <Wrapper className="ml-auto mr-auto p-5 text-center">
      <h3 className="text-blue">Ops! Não há registros.</h3>
      <p className="text-secondary">
        O nosso website está em processo de atualização, em breve você poderá ver galerias de projetos e serviços que já executados ou que estão em andamento.
      </p>
    </Wrapper>
  </center>
);

const Wrapper = _.section`
  width: 50%;

  @media (max-width: 576px) {
    width: 100%;
  }
`;
