import React, { useEffect, useState } from "react";
import { Work } from "../models";
import { PortfolioPage } from "../views";

const PortfolioController = () => {
  const [data, setData] = useState([]);
  const [busy, setBusy] = useState(true);

  useEffect(() => { handleOnLoad() }, []);

  const handleOnLoad = async () => {
    try {
      const { data } = await Work.portfolios();
      setData(data);
    }
    catch (err) {
      console.log(err);
    }
    finally {
      setBusy(false);
    }
  }

  const viewProps = {
    data, busy
  }
  return <PortfolioPage {...viewProps} />
};

export default PortfolioController;
