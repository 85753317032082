import React from "react";
import PropType from "prop-types";

const Button = ({ title, onClick, type, busy }) => (
  <button className={`btn btn-${type}`} onClick={onClick}>
    {busy ? "Aguarde..." : title}
  </button>
);

Button.defaultProps = {
  busy: false,
  type: "primary"
};

Button.propTypes = {
  title: PropType.string.isRequired,
  action: PropType.func
};

export default Button;
