import React, { useState } from "react";
import Mask from "vanilla-masker";

import { Contact } from "../models";
import { ContactPage } from "../views";

const ContactController = () => {
  const [params, setParams] = useState({ ...Contact.schema });
  const [notice, setNotice] = useState({});
  const [busy, setBusy] = useState(false);

  const handleOnChange = event => {
    let value = event.target.value;

    if (event.target.name === "phone") {
      value = Mask.toPattern(
        event.target.value,
        event.target.value.length > 14 ? "(99) 99999-9999" : "(99) 9999-9999"
      );
    }

    setParams({ ...params, [event.target.name]: value });
  };

  const handleOnSubmit = async event => {
    event.preventDefault();

    try {
      setBusy(true); setNotice({});

      const object = new Contact(params);
      await object.send();

      setNotice({
        message: `Sua mensagem foi enviada com sucesso!`,
        type: `success`
      });

      setParams({ ...Contact.schema });
    } catch (err) {
      setNotice({ message: `${err.message}`, type: `danger` });
    } finally {
      setBusy(false);
    }
  };

  const viewProps = {
    params,
    notice,
    busy,
    handleOnChange,
    handleOnSubmit
  };

  return <ContactPage {...viewProps} />;
};

export default ContactController;
