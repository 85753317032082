import React from "react";
import _ from "styled-components";

const TextInput = props => <Input {...props} />;

TextInput.defaultProps = {
  className: "form-control mb-3"
};

const Input = _.input``;

export default TextInput;
