import React from "react";
import {
  Layout,
  Heading,
  Container,
  Form,
  TextInput,
  Row,
  Col,
  TextArea,
  Submit,
  Notification
} from "../../components";

const Page = props => (
  <Layout>
    <Heading
      title="Trabalhe Conosco"
      label="Está interessando(a) em fazer parte da nossa equipe?"
    />
    <Container className="pt-5 pb-5 text-gray contact-form animated fadeIn">
      <div className="form-center">
        <Form onSubmit={props.handleOnSubmit}>
          <h2 className="text-blue">Candidate-se agora!</h2>
          <p>
            Preencha os campos abaixo para enviar os seus dados para nosso departamento de novas contratações:
          </p>
          <Notification {...props.notice} />
          <Row>
            <Col col={12}>
              <TextInput
                name="name"
                value={props.params.name}
                onChange={props.handleOnChange}
                disabled={props.busy}
                placeholder="Nome completo"
              />
            </Col>
          </Row>
          <Row>
            <Col col={12} md={4}>
              <TextInput
                name="email"
                value={props.params.email}
                onChange={props.handleOnChange}
                disabled={props.busy}
                placeholder="E-mail"
              />
            </Col>
            <Col col={12} md={4}>
              <TextInput
                name="phone"
                value={props.params.phone}
                onChange={props.handleOnChange}
                disabled={props.busy}
                placeholder="Telefone"
              />
            </Col>
            <Col col={12} md={4}>
              <TextInput
                name="birthdate"
                value={props.params.birthdate}
                onChange={props.handleOnChange}
                disabled={props.busy}
                placeholder="Data Nascimento"
              />
            </Col>
          </Row>
          <Row>
            <Col col={12}>
              <TextInput
                name="address"
                value={props.params.address}
                onChange={props.handleOnChange}
                disabled={props.busy}
                placeholder="Endreço completo"
              />
            </Col>
          </Row>
          <Row>
            <Col col={12}>
              <TextArea
                name="resume"
                value={props.params.resume}
                onChange={props.handleOnChange}
                disabled={props.busy}
                rows={12}
                placeholder="Escreva aqui um pouco sobre você"
              />
            </Col>
          </Row>
          <Row>
            <Col col={12}>
              <TextArea
                name="experience"
                value={props.params.experience}
                onChange={props.handleOnChange}
                disabled={props.busy}
                rows={12}
                placeholder="Escreva aqui sobre suas experiências"
              />
            </Col>
          </Row>
          <Submit title="Enviar candidatura!" busy={props.busy} />
        </Form>
      </div>
    </Container>
  </Layout>
);

export default Page;
