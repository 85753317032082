import React, { useEffect, useState, createContext } from "react";
import { Settings } from "../models";
import { defaultSettings } from "../helpers";

export const SettingsContext = createContext({});

export const SettingsProvider = props => {
  const [settings, setSettings] = useState({ ...defaultSettings });

  useEffect(() => { handleOnSettings() }, []);

  const handleOnSettings = async () => {
    const { data } = await Settings.all();
    setSettings({ ...data.attributes });
  };

  return (
    <SettingsContext.Provider value={settings}>
      {props.children}
    </SettingsContext.Provider>
  );
};
