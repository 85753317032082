import React from "react";
import PropType from "prop-types";
import _ from "styled-components";

const Image = props => <Picture {...props} />;

Image.propTypes = {
  src: PropType.string.isRequired,
  alt: PropType.string.isRequired
};

const Picture = _.img`
  max-width: 100%;
`;

export default Image;
