import React from "react";
import { Link } from "react-router-dom";
import { Background, Container, Row, Col, Image } from "../../components";

const Call = () => (
  <section id="contact" className="pt-5">
    <Background src={require("../../assets/sketch.png")}>
      <Container>
        <Row>
          <Col col={6} hiddenSmDown>
            <center>
              <Image
                src={require("../../assets/man.png")}
                alt="Engelar é qualidade"
              />
            </center>
          </Col>
          <Col col={12} md={6} className="d-flex align-items-center">
            <div>
              <h2>
                <span className="text-green text-3 text-uppercase">
                  Alguma dúvida?
                </span>
                <strong className="d-block text-big text-blue text-uppercase">
                  Fale Conosco
                </strong>
              </h2>
              <p className="text-gray">
                Atendimento <strong>SEG à SEX das 08h00 às 18h00.</strong>
              </p>
              <Link
                to="/fale-conosco"
                className="btn rounded-pill btn-outline-success mb-5"
              >
                Clique aqui
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Background>
  </section>
);

export default Call;
