import React, { useEffect, useState } from "react";
import { HomePage } from "../views";
import { Slider } from "../models";

const HomeController = () => {
  const [sliders, setSliders] = useState([]);

  useEffect(() => { handleOnSliders() }, []);

  const handleOnSliders = async () => {
    const { data } = await Slider.all();
    setSliders(data);
  };

  return <HomePage sliders={sliders} />
};

export default HomeController;
