import Axios from "axios";
import { host } from "../config";

class Request {
  static async get(path = "") {
    return Axios.get(`${host}/${path}`)
      .then(response => Promise.resolve(response.data))
      .catch(err => {
        const { data } = err.response.data;
        return Promise.reject(data);
      });
  }

  static async post(path = "", params = {}) {
    return Axios.post(`${host}/${path}`, params)
      .then(response => Promise.resolve(response.data))
      .catch(err => {
        const { data } = err.response.data;
        return Promise.reject(data);
      });
  }
}

export default Request;
