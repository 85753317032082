import React from "react";
import PropType from "prop-types";
import _ from "styled-components";

const Submit = props => (
  <Button {...props} disabled={props.busy}>
    {props.busy ? "Aguarde..." : props.title}
  </Button>
);

Submit.defaultProps = {
  type: "submit",
  busy: false,
  className: "btn btn-primary"
};

Submit.propTypes = {
  title: PropType.string.isRequired,
  busy: PropType.bool.isRequired
};

const Button = _.button``;

export default Submit;
