import React, { useEffect } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import {
  HomeController,
  AboutController,
  ProductsController,
  PortfolioController,
  CandidateController,
  ContactController
} from "../controllers";

const Routes = ({ history }) => {
  useEffect(() => {
    const listener = history.listen(() =>
      window.scrollTo({ top: 0, behavior: "smooth" })
    );

    return () => listener();
  }, [history]);

  return (
    <Switch>
      <Route exact path="/" component={HomeController} />
      <Route exact path="/quem-somos" component={AboutController} />
      <Route exact path="/produtos" component={ProductsController} />
      <Route exact path="/portifolio" component={PortfolioController} />
      <Route exact path="/trabalhe-conosco" component={CandidateController} />
      <Route exact path="/fale-conosco" component={ContactController} />
    </Switch>
  );
};

export default withRouter(Routes);
