import React from "react";
import _ from "styled-components";

const TextArea = props => <Input {...props} />;

TextArea.defaultProps = {
  className: "form-control mb-3",
  rows: 3
};

const Input = _.textarea`
  min-height: 8rem;
  max-height: 10rem;
`;

export default TextArea;
