import React from "react";

export default props => (
  <center>
    <img
      {...props}
      src={require("../../assets/loader.gif")}
      alt="Loading..."
    />
  </center>
);
