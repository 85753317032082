import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Background, Container, Row, Col } from "../../components";
import _ from "styled-components";

const Heading = ({ title, label }) => (
  <Background src={require("../../assets/heading.jpg")}>
    <Helmet>
      <title>Engelar: {title}</title>
      <meta name="description" content={label} />
    </Helmet>
    <Container>
      <Row>
        <Col className="pt-3 pb-3 pt-md-5 pb-md-5 animated fadeIn">
          <Title>{title}</Title>
          <Label>{label}</Label>
        </Col>
      </Row>
    </Container>
  </Background>
);

Heading.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

const Title = _.h1`
  font-size: 2.5rem;
  color: #a8c85a;
  font-weight: 700;
  text-transform: uppercase;

  @media(max-width: 768px) {
    text-align: center;
  }
`;

const Label = _.p`
  width: 60%;
  font-size: 1.25rem;
  color: #30405a;

  @media(max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

export default Heading;
