import { Request } from ".";

class Work {
  static async products() {
    return await Request.get("website/works/product");
  }

  static async portfolios() {
    return await Request.get("website/works/portfolio");
  }
}

export default Work;
