import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Icon } from "../../components";
import _ from "styled-components";

import { SettingsContext } from "../../contexts/settings";

const Session = () => (
  <Wrapper>
    <Container className="animated fadeInDown">
      <Row>
        <Col
          col={12}
          md={8}
          className="p-0 pl-md-3 d-flex justify-content-center justify-content-md-start">
          <nav>
            <Link to="/quem-somos">
              <Icon className="mdi-chevron-right" />
              Quem Somos
            </Link>
            <Link to="/fale-conosco" className="ml-2 mr-2 ml-md-3 mr-md-3">
              <Icon className="mdi-chevron-right" />
              Fale Conosco
            </Link>
            <Link to="/trabalhe-conosco">
              <Icon className="mdi-chevron-right" />
              Trabalhe Conosco
            </Link>
          </nav>
        </Col>
        <Col
          col={12} md={4}
          className="p-0 pr-md-3 d-flex justify-content-center justify-content-md-end">
            <SettingsContext.Consumer>
              {
                ({ facebook, instagram, linkedin, youtube }) => (
                  <>
                    <SocialLink href={facebook} target="_blank">
                      <Icon className="mdi-facebook" />
                    </SocialLink>
                    <SocialLink href={instagram} target="_blank">
                      <Icon className="mdi-instagram" />
                    </SocialLink>
                    <SocialLink href={linkedin} target="_blank">
                      <Icon className="mdi-linkedin" />
                    </SocialLink>
                    <SocialLink href={youtube || 'https://www.youtube.com/channel/UCVS0CxibGC5sgROKjobRFZw'} target="_blank">
                      <Icon className="mdi-youtube" />
                    </SocialLink>
                  </>
                )
              }
            </SettingsContext.Consumer>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

const Wrapper = _.div`
  background-color: #30405A;
  padding: 8px 0

  a {
    font-size: .85rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.65);

    &:hover {
      color: white;
    }
  }

  @media (max-width: 576px) {
    a { font-size: .75rem }
  }
`;

const SocialLink = _.a`
  i {
    font-size: 1rem;
    margin-left: 1rem
  }

  @media (max-width: 576px) {
    i { font-size: 1.5rem }
  }
`;

export default Session;
