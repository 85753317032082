import { Request } from ".";

class Candidate {
  constructor(attributes = {}) {
    this.attributes = { ...attributes };
  }

  async send() {
    return await Request.post("candidates", this.attributes);
  }
}

Candidate.schema = {
  name: "",
  email: "",
  phone: "",
  birthdate: "",
  resume: "",
  experience: "",
  address: ""
};

export default Candidate;
