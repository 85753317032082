import React from "react";
import { Session, Header, Footer, Call } from "../../components";
import _ from "styled-components";

const Layout = ({ children }) => (
  <Wrapper>
    <Session />
    <Header />
    {children}
    <Call />
    <Footer />
  </Wrapper>
);

const Wrapper = _.div``;

export default Layout;
